@import "@/styles/variables";
@import "@/styles/mixins";

.navbar {
  position: sticky;
  _:-ms-fullscreen, :root .ie11up {
    position: fixed;
  }
  top: 0;
  width: 100%;
  min-height: $navbar-height;
  display: flex;
  flex-direction: column;
  background-color: var(--primary);
  border-bottom: 1px solid #000000;
  z-index: 1000;

  .announcementBox {
    display: flex;
    flex-direction: row;
    font-size: 11px;
    background-color: var(--background);
    border-bottom-color: #000;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    width: 100%;
    .announcementMessage {
      width: 100%;
      padding-top: 10px;
      padding-left: 24px;
      padding-bottom: 7px;
    }
    .announcementMessageClose {
      margin: auto;
      height: 100%;
      padding: 10px;
      width: 64px;
    }
  }

  .navBarContentContainer {
    display: flex;
    flex-direction: row;
  }

  .logoContainer {
    $padding: 16px;
    flex: 1;
    padding: $padding;

    .logo {
      height: calc($navbar-height - 2 * $padding);
    }
  }

  .vertical {
    display: flex;
    align-items: center;
    padding: 0 12px;
    border-left: 1px solid #000000;
  }

  .menuButton {
    padding: 20px;
    border-left: 1px solid #000000;
  }
}

.menu {
  height: 0;
  width: 100%;
  position: fixed;
  z-index: 1000;
  margin-top: 1px;
  top: $navbar-height;
  left: 0;
  overflow-y: hidden;
  background: var(--primary);
  transition: height 200ms ease-in-out;

  .leftColumn {
    display: flex;
    flex-direction: column;
    height: calc(100vh - $navbar-height);
  }

  ul {
    flex: 1;
    padding: 48px;
    margin: 0;

    li {
      list-style: none;
    }
  }

  .social {
    padding: 48px;
  }

  .rightColumn {
    border-left: 1px solid #000000;
    padding-top: 48px;
    padding-left: 48px;

    a {
      margin-top: 24px;
    }
  }

  &.open {
    height: calc(100vh - $navbar-height);
  }
}

@include desktop {
  .menu ul li + li {
    margin-top: 24px;
  }

  .vertical {
    border-left: 1px solid #000000;
  }
}
