.footer {
  background: var(--primary);
  border-top: 1px solid #000000;
  padding: 48px 24px;

  .logo {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

@media (min-width: 576px) {
  .footer {
    padding: 0px 24px;

    [class^="col-"] {
      padding-top: 48px;
    }

    .borderLeft {
      padding-top: 48px;
      padding-bottom: 48px;
      padding-left: 32px;
      border-left: 1px solid #000000;
    }

    .logo {
      margin-top: 0px;
    }
  }
}
