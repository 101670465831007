@import "@/styles/mixins";

$color: #000000;

.btn {
  padding: 12px 24px;
  border: none;
  border-radius: 100px;
  box-shadow: none;
  text-transform: uppercase;
  color: $color;

  * {
    cursor: pointer;
  }

  &.primary {
    background-color: var(--primary);
  }

  &.secondary {
    background-color: var(--background);
    border: 1px solid #000000;
  }

  &.outlined {
    padding: 9px 24px 8px 24px;
    border: 1px solid #000000;
    background: none;
    box-shadow: none;
  }

  &.icon {
    border: 1px solid #000000;
    background: none;
    box-shadow: none;
    width: 32px;
    height: 32px;
    padding: 0px;
  }

  &.image {
    background: none;
    box-shadow: none;
    width: 32px;
    height: 32px;
    padding: 0px;
  }
}


@include desktop {
  .minimumWidth{
    min-width: 320px;
  }
}

@include mobile {
  .minimumWidth{
    min-width: 300px;
  }
}


.btnWithShadow {
  border: none;
  background: none;
  position: relative;
  width: 300px;
  height: 42px;
  color: $color;
  max-width: 90vw;
  padding: 0;


  .background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--primary);
    padding: 12px 24px;
    border: 1px #000 solid;
    border-radius: 100px;
    z-index: 1;
    transition: all 150ms ease-in-out;

    p {
      line-height: 1.1em;
    }
  }

  .shadow {
    position: absolute;
    top: -4px;
    left: 20px;
    width: 100%;
    height: 172%;
    background-size: contain;
    background-repeat: no-repeat;
    transform: scale(1.1);
    transition: all 150ms ease-in-out;

    &.primary {
      background-image: var(--shadow-button);
    }
  }

  &:active {
    .background {
      transform: translateY(2px);
    }
    .shadow {
      width: 100%;
      left: 10px;
      transform: translateY(-4px) scale(0.925);
    }
  }
}

@include mobile {
  .btn.outlined p {
    font-size: 0.8em;
  }
}

@include desktop {
  .btnWithShadow {
    width: 320px;
    height: 48px;

    .background {
      padding-top: 14px;
      padding-bottom: 14px;
    }

    .shadow {
      top: 0px;
      left: 20px;
      width: 100%;
      height: 160%;
      transform: scale(1.1);
    }

    &:active {
      .background {
        transform: translateY(2px);
      }
      .shadow {
        width: 100%;
        left: 10px;
        transform: translateY(-4px) scale(0.925);
      }
    }
  }
}