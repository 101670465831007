.numberedList {
	display: flex;
	flex-direction: row;

	.number {
		width: 14px;
		height: 14px;
		border-radius: 50%;
		border: 1px solid #000000;
		margin-right: 12px;
		margin-top: 5px;
		display: flex;
		align-content: center;
		justify-content: center;
		box-sizing: content-box;
		font-size: 12px;
		line-height: 1.2;
		p {
			line-height: 24px;
		}
	}

	.numberInverted {
		width: 14px;
		height: 14px;
		border-radius: 50%;
		border: 1px solid #ffffff;
		background: #ffffff;
		margin-right: 12px;
		margin-top: 5px;
		display: flex;
		align-content: center;
		justify-content: center;
		box-sizing: content-box;
		font-size: 18px;
		line-height: 1.2;
		p {
			line-height: 24px;
		}
	}

	.text {
		flex: 1;
	}

	& + .numberedList {
		margin-top: 14px;
	}
}
