.toggle {
  position: relative;
  width: 66px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  border-left: 1px solid #000000;

  span {
    position: absolute;
    width: 30px;
    height: 2px;
    background: #000000;
    border-radius: 2px;
    transition: 0.4s;

    &:nth-child(1) {
      transform: translateY(-10px);
      left: 17px;
    }

    &:nth-child(2) {
      transform: translateY(10px);
      left: 17px;
    }
  }

  &.active {
    span {
      &:nth-child(1) {
        width: 30px;
        transform: translateY(0) rotate(45deg);
        transition-delay: 0.1s;
      }

      &:nth-child(2) {
        width: 30px;
        transform: translateY(0) rotate(135deg);
        transition-delay: 0.2s;
      }

      &:nth-child(3) {
        transform: translateX(60px);
      }
    }
  }
}
