@import "@/styles/variables";
@import "@/styles/mixins";


@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-12px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes bounceHorizontal {
  0% {
    transform: translateX(-6px);
  }
  50% {
    transform: translateX(6px);
  }
  100% {
    transform: translateX(-6px);
  }
}

.bounceLeftAndRight {
  animation-name: bounceHorizontal;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.numberedListSection {
  padding: 24px;

  h2 + * {
    margin-bottom: 32px;
  }
}

.aboutUs {
  .aboutUsContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 6rem 0;
  }

  &.primary .aboutUsContent {
    background: var(--primary);
  }

  &.secondary .aboutUsContent {
    background: var(--secondary);

    img {
      /* svg color: #FFFFFF */
      /* REF: https://codepen.io/sosuke/pen/Pjoqqp */
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(175deg)
        brightness(104%) contrast(101%);
    }
  }

  .content {
    position: relative;
    z-index: 1;
    color: #ffffff;
    top: -50%;

    img {
      /* svg color: #FFFFFF */
      /* REF: https://codepen.io/sosuke/pen/Pjoqqp */
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(175deg)
        brightness(104%) contrast(101%);
    }
  }

  p {
    margin-bottom: 24px;
  }
}

.illustrationSectionPadding {
  padding-bottom: 48px;
}

.illustrationSectionLeft {
  padding: 48px;
}

@include desktop {
  .marginTop {
    margin-top: 48px;
  }
  .centerOnSmallScreens {
    text-align: left;
  }

  .heroSection {
    h1 {
      padding: 20vh 0px 32px 0px;
      max-width: 70vw;
    }

    .title {
      padding-top: 5vh;
    }
  }

  .numberedListSection {
    padding-top: 48px;
    padding-bottom: 72px;
  }

  .sitesSection {
    margin-top: 12px;

    .sitesList {
      grid-auto-flow: column;
      grid-template-columns: unset;
    }
  }
}
