@import "@/styles/variables";
@import "@/styles/mixins";


.border {
  // enable-background: new 0 0 500 100;
  height: 50px;
  margin-top: -2px;
  background: url(/images/pixelated-border.svg);
  z-index: -1;
  background-repeat-x: repeat;
  background-repeat-y: no-repeat;

  @include mobile {
    height: 40px;
  }

  &.secondary {
    background: url(/images/pixelated-border-secondary.svg);
  }

  &.flip {
    transform: rotate(180deg);
    margin-bottom: -2px;
  }
}
