@import "@/styles/mixins";

.imageWithBall {
  height: 28rem;
  width: 100%;
  position: relative;
  background: var(--background);
  overflow: hidden;

  .background {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .ballContainer {
    position: absolute;
    top: 1rem;
    right: 2rem;
    width: 10rem;
    height: 10rem;

    .ball {
      z-index: 1;
    }

    .whiteTextColor {
      color: #FFF;
    }

    .blackTextColor {
      color: #000;
    }

    .textContainer {
      $padding: 24px;
      position: absolute;
      top: $padding * 2;
      right: $padding;
      bottom: $padding;
      left: $padding;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
}

@include desktop {
  .imageWithBall {
    height: 33rem;

    .ballContainer {
      position: absolute;
      top: 2rem;
      right: 4rem;
      width: 16rem;
      height: 16rem;

      .textContainer {
        $padding: 32px;
        top: $padding * 2;
        right: $padding;
        bottom: $padding;
        left: $padding;
      }
    }
  }
}
