@import "@/styles/mixins";

.rounded {
  border-radius: 50%;
  border: 1px solid #fff;
}

@include mobile {
  .desktop {
    display: none;
  }
}
