@import "@/styles/mixins";

.container {
    a {
        color: black;
        text-decoration: none;
        line-height: 23px;
    }
    .flexContainer {
        display: flex;
        .leftContainer {
            margin: auto 0;
        }
        .rightContainer {
            position: relative;
            width: 50px;
            text-align: left
        }
    }
}

.arrow {
    max-height: 36px;
    margin-left: 10px;
    @include desktop {
        margin-left: 40px;
    }
}

.textColorBlack {
    a {
        color: #000 !important;
        text-decoration: none;
    }
}

.textColorWhite {
    a {
        color: #FFF !important;
        text-decoration: none;
    }
}