@import '@/styles/variables';
@import '@/styles/mixins';

.centerOnSmallScreens {
  text-align: center;
}

.heroSection {
  @extend .centerOnSmallScreens;
  height: calc(100vh - $navbar-height);
  color: #ffffff;
  overflow: hidden;
  width: 100%;

  .topBackgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(90%);
  }

  @include desktop-zoom {
    h1 {
      font-size: 1.8rem;
      padding: 10vh 1px 16px 24px;
    }

    .uspContainer {
      display: none;
      place-self: center;
      width: 95%;
      padding: 2px 2px 50px 2px;
      text-align: left;
    }
  }

  @include mobile {
    height: calc(100vh - $navbar-height);

    .uspContainer {
      place-self: center;
      width: 95%;
      padding: 2px 2px 25px 2px;
      text-align: left;
    }




    h1 {
      padding: 20vh 24px 16px 24px;
    }

    h1[data-value='Pollenkollen'] {
      padding: 5vh 24px 16px 24px;
    }

    h1[data-value='Aknekollen'] {
      padding: 15vh 24px 16px 24px;
    }
  }

  @include desktop {
    .uspContainer {
      place-self: center;
      width: 95%;
      padding: 2px 2px 50px 2px;
      text-align: left;
    }

    h1 {
      padding: 20vh 24px 16px 24px;
    }
  }

  .wrapper {
    height: calc(100vh - $navbar-height);
    width: 100%;
    top: calc($navbar-height);
    left: 0;
    z-index: 3;
    position: fixed;
    background: rgba(#390020, .6);
  }

  .scrollDownButton {
    $size: 48px;
    position: absolute;
    left: calc(50% - $size / 2);
    bottom: 12px;
    animation-name: bounce;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-delay: 4s;

    button {
      border: none;

      img {
        width: 48px;
        height: 48px;
        /* svg color: #FFFFFF */
        /* REF: https://codepen.io/sosuke/pen/Pjoqqp */
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(175deg) brightness(104%) contrast(101%);
      }
    }
  }

}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-12px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes bounceHorizontal {
  0% {
    transform: translateX(-6px);
  }

  50% {
    transform: translateX(6px);
  }

  100% {
    transform: translateX(-6px);
  }
}

@include desktop {
  .centerOnSmallScreens {
    text-align: left;
  }

  .heroSection {
    h1 {
      padding: 20vh 0px 32px 0px;
      max-width: 70vw;
    }

    .title {
      padding-top: 5vh;
    }
  }
}
