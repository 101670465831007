@import "@/styles/variables";
@import "@/styles/mixins";

.forecast {
	padding: 8px;
	background: rgba(#f6f2eb, 1);
	color: black;
	border: 1px solid black;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	width: 100%;
}

.fadeIn {
	animation: fadeIn 2s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.slideInDown {
	opacity: 0;
	animation-delay: 100ms;
	animation-fill-mode: forwards;
	animation-name: slideInDown;
	animation-duration: 800ms;
	animation-timing-function: ease-in-out;
	display: inline-block;
}

@keyframes slideInDown {
	0% {
		opacity: 0;
		transform: translateY(-20px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}


@keyframes forecast {
	0% {
		opacity: 0;
		transform: translateY(-20px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.forecast_empty {
	width: 100%;
	height: 100vh;
}

.forecast_close {
	position: absolute;
	width: 66px;
	height: 64px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	overflow: hidden;
	right: 0;
	top: 0;

	span {
		position: absolute;
		width: 30px;
		height: 2px;
		background: #000000;
		border-radius: 2px;
		transition: 0.4s;

		&:nth-child(1) {
			transform: translateY(-10px);
			left: 17px;
		}

		&:nth-child(2) {
			transform: translateY(10px);
			left: 17px;
		}
	}

	span {
		&:nth-child(1) {
			width: 30px;
			transform: translateY(0) rotate(45deg);
			transition-delay: 0.1s;
		}

		&:nth-child(2) {
			width: 30px;
			transform: translateY(0) rotate(135deg);
			transition-delay: 0.2s;
		}

		&:nth-child(3) {
			transform: translateX(60px);
		}
	}

}

.forecast_region {
	font-weight: bold;
	font-size: 1.3rem;
	text-align: center;
	margin-bottom: 10px;

	select {
		background: var(--background);
		border: 0px;
		width: auto;
		text-decoration: underline;
	}

}

.forecast_weekday_container {

	display: grid;
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	width: 100%;
	flex-direction: row;
}

.forecast_weekday_container div p {
	text-align: center;
	font-weight: bold;
	text-transform: capitalize;

	@include desktop {
		font-size: 1.3rem;
	}

	@include mobile {
		font-size: 1.0rem;
	}
}

.forecast_weekday_container div:hover {
	color: #fff;
	background-color: var(--secondary);
}

.forecast_weekday_container div {
	width: 20%;
	border-left: 2px solid #000;
}

.forecast_weekday_container div:last-child {
	border-right: 2px solid #000;
}

.forecast_weekday_container div[data-value="active"] {
	background-color: var(--secondary);
	color: #fff;
}



.forecast_data_container {
	height: 100px;
	gap: 10px;
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	width: 100%;
	flex-direction: row;
}

.forecast_data {
	width: 30%;
	display: flex;
	flex-direction: column;
}

.forecast_bar {
	height: 100%;
	padding: 4px;
	text-align: center;
	vertical-align: middle;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	p:first-child {
		font-weight: bold;
	}

}

.forecast_bar[data-value="1"] {
	background-color: rgb(253 230 138);
}

.forecast_bar[data-value="2"] {
	background-color: rgb(252 211 77);
}


.forecast_bar[data-value="3"] {
	background-color: rgb(255 226 149);
}

.forecast_bar[data-value="4"] {
	background-color: rgb(255 186 77);
}

.forecast_bar[data-value="5"] {
	background-color: rgb(251 144 46);
}

.forecast_bar[data-value="6"] {
	background-color: rgb(245 113 27);
}

.forecast_bar[data-value="7"] {
	background-color: rgb(236 54 23);
}

.forecast_copyright {
	padding-top: 5px;
	text-align: center;
	font-size: 0.8rem;
	color: gray;
	font-style: italic;

}
