@import "@/styles/variables";
@import "@/styles/mixins";


.centerOnSmallScreens {
  text-align: center;
}

.primaryBackgroundColor {
  background-color: var(--primary);
}

.secondaryBackgroundColor {
  background-color: var(--secondary);
}

.tertiaryBackgroundColor {
  background-color: var(--tertiary);
}

.heroSection {
  @extend .centerOnSmallScreens;
  color: #000;
  background-color: var(--primary);
  overflow: hidden;
  position: relative;
  margin-top: 48px;

  @include mobile {
    margin-top: unset;
  }


  .leftContainer {
    min-height: 500px;
    margin: auto;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;

    .textSection {
      padding: 48px;

      .buttonOneContainer {
        margin-bottom: 3rem !important;
      }

    }


    .textContainer {
      margin-bottom: 30px;
      margin-top: 10px;

      .subHeading {
        font-family: "Libre Franklin";
      }

    }

  }

  .rightContainer {
    display: flex;
    flex-direction: column;
    //padding-bottom: 20px;
    //padding-top: 20px;
    justify-content: center;

    @include mobile {
      //padding-left: 25px;
      //padding-right: 25px;
    }


    .video {
      max-height: 400px;

      @include mobile {
        max-height: 230px;
      }
    }

  }

  h1 {
    //padding: 20vh 24px 16px 24px;
  }

  .scrollDownButton {
    $size: 48px;
    position: absolute;
    left: calc(50% - $size / 2);
    bottom: 32px;
    animation-name: bounce;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-delay: 4s;

    button {
      border: none;

      img {
        width: 48px;
        height: 48px;
        /* svg color: #FFFFFF */
        /* REF: https://codepen.io/sosuke/pen/Pjoqqp */
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(175deg) brightness(104%) contrast(101%);
      }
    }
  }
}

@include desktop {
  .centerOnSmallScreens {
    text-align: left;
  }

  .heroSection {
    h1 {
      //padding: 20vh 0px 32px 0px;
      max-width: 70vw;
    }

    .title {
      padding-top: 5vh;
    }
  }

}
