.previewBanner {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ff0000;
  color: #ffffff;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.8rem;
  z-index: 1001;
  border-bottom-right-radius: 8px;

  a {
    color: #ffffff;
    font-weight: bold;
    margin: 0 12px;
  }
}
