.textColorWhite {
  color: #fff !important;
}
.textColorBlack {
  color: #000 !important;;
}

.backgroundColorPrimary {
  background: var(--primary) !important;
}
.backgroundColorSecondary {
  background: var(--secondary) !important;
}
.backgroundColorTertiary {
  background: var(--tertiary) !important;
}

.testimonial {
  .items {
    padding: 48px 24px;
    background: var(--primary);
    .item {
      height: 10rem;
      max-width: 30rem;
    }
  }
}
