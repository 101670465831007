@import "@/styles/mixins";

.link {
  display: inline-block;
  text-decoration: none;
  color: #000000;

  &:hover {
    text-decoration: underline;
    color: #000000;
  }
}

.iconLink {
  $size: 32px;
  text-decoration: none;
  width: $size;
  height: $size;
  border-radius: 50%;
  background-color: #000000;
  display: inline-block;
  text-align: center;
  line-height: calc($size - 4px);

  img {
    width: 18px;
    height: 18px;
    /* svg color: #FFFFFF */
    /* REF: https://codepen.io/sosuke/pen/Pjoqqp */
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(175deg)
      brightness(104%) contrast(101%);
  }

  &:hover {
    text-decoration: underline;
    color: #ffffff;
  }

  & + .iconLink {
    margin-left: 6px;
  }
}

@include desktop {
  .iconLink {
    $size: 48px;
    width: $size;
    height: $size;
    line-height: calc($size - 1px);

    img {
      width: 24px;
      height: 24px;
    }

    & + .iconLink {
      margin-left: 24px;
    }
  }
}
