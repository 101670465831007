@import "@/styles/variables";
@import "@/styles/mixins";

.centerOnSmallScreens {
  text-align: center;
}

.primaryBackgroundColor {
  background-color: var(--primary);
}

.secondaryBackgroundColor {
  background-color: var(--secondary);
}

.tertiaryBackgroundColor {
  background-color: var(--tertiary);
}

.heroPromoKollar {
  @extend .centerOnSmallScreens;
  color: #000;
  background-color: var(--primary);
  overflow: hidden;
  position: relative;
  margin-top: 48px;

  @include mobile {
    margin-top: unset;
  }

  .divider {
    width: 80%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    border-bottom: dashed 1px #000000;
  }

  .promoContainer {
    width: 83%;
    margin-bottom: 30px;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
    flex-basis: content;

    .subHeading {
      font-family: "Libre Franklin";
    }
  }
}

@include desktop {
  .centerOnSmallScreens {
    text-align: left;
  }

  .heroSection {
    h1 {
      //padding: 20vh 0px 32px 0px;
      max-width: 70vw;
    }

    .title {
      padding-top: 5vh;
    }
  }
}
