@import "@/styles/variables";
@import "@/styles/mixins";

.containerSection {
  margin: auto;
  max-width: 1320px;
}

.illustrationSectionPadding {
  //padding-top: 48px;
  padding-bottom: 48px;
  @include mobile {
    padding-bottom: unset;
  }
}

.illustrationSectionLeft {
  padding: 48px;
  @include mobile {
    //padding: unset;
  }
}

.rightSection {
  padding: 48px;
  @include mobile {
    padding:unset;
  }
}

@include desktop {
  .marginTop {
    margin-top: 48px;
  }
}

