@import "@/styles/variables";
@import "@/styles/mixins";


.section {
  margin-top: 48px;
  .firstSection {
    padding: 44px 44px 44px 44px;
  }
  @include mobile {
    margin-top: unset;
    .firstSection {
      padding: 0px 44px 44px 44px;
    }
  }

}

.secondSection {
  padding: 24px 48px 24px 48px;
  @include mobile {
    padding: 24px 0px 24px 0px;
  }
}

.numberedListSection {
  //padding: 24px;

  h2 + * {
    margin-bottom: 32px;
  }
}

.certificateLogo {
  margin-top: 32px;
  margin-left: 26px;
}

@include desktop {
  .centerOnSmallScreens {
    text-align: left;
  }

  .numberedListSection {
    margin-bottom: 32px;
  }
}
