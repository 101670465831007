.delay-600 {
  opacity: 0;
  animation-delay: 600ms;
  animation-fill-mode: forwards;
}

.delay-800 {
  opacity: 0;
  animation-delay: 800ms;
  animation-fill-mode: forwards;
}

.slideInLeft {
  animation-name: slideInLeft;
  animation-duration: 1000ms;
  animation-timing-function: ease-in-out;
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slideInDown {
  animation-name: slideInDown;
  animation-duration: 700ms;
  animation-timing-function: ease-in-out;
  display: inline-block;
}

@keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
