@import "@/styles/mixins";

.carousel {
  position: relative;

  .items {
    .item {
      height: 100% !important;
      max-width: 30rem;
    }
  }
  .paginator {
    .indicator {
      display: inline-block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: var(--background);
      border: 1px solid #000000;
      transition: background 800ms ease-in-out;

      &.active {
        background: #000000;
      }

      & + .indicator {
        margin-left: 8px;
      }
    }
  }
}

.mascotContainer {
  min-height: 300px;
}

.image {
  position: absolute;
  bottom: 24px;
  right: 24px;
  max-width: min(100%, 240px);
  pointer-events: none;
}

@include desktop {
  .carousel .items .item {
    height: 10rem;
  }
}
