@import "@/styles/mixins";

.backgroundColorPrimary {
  background-color: var(--primary) !important;
}

.backgroundColorSecondary {
  background-color: var(--secondary) !important;
}

.backgroundColorTertiary {
  background-color: var(--tertiary) !important;
}

.textColorBlack {
  color: #000 !important;
}

.textColorWhite {
  color: #FFF !important;
}

.section {
  padding-top: 48px;
}

.layout {
  .imageContainer {
    padding: 48px;
  }

  .textualContent {
    //padding: 0px 24px 24px 24px;
  }

  h2 + * {
    margin-bottom: 32px;
  }
}

.doctorContainer {
  display: flex;
  border: 1px solid #fff;
}

.doctorContainerLeft {
  flex: 1;
  max-width: 100px;
  border-right: 1px solid #fff;
  text-align: center;
}

.doctorContainerRight {
  flex: 1;
  margin: auto;
  padding: 10px;
}

@include desktop {
  .marginTop {
    margin-top: 48px;
  }
  .layout {
    padding-bottom: 48px;

    .imageContainer {
      padding-top: 0px;
    }
  }
}
