.navbarMode {
  background-color: #fff !important;
}

.sitesSection {
  background-color: var(--background);

  h1 {
    padding: 32px 6px 32px 6px;
  }

  .sitesList {
    display: flex;
    flex: 1;
    flex-direction: column;
    border-top: 1px solid black;
    border-left: 1px solid black;

    .siteBlock {
      vertical-align: middle;
      text-align: center;
      border-right: 1px solid black;
      height: 65px;
      overflow: hidden;
      padding: 8px 5px !important;

      a {
        font-size: 12px;
      }

      .mobileSiteLink {
        display: inline-block;
        width: 120px;
        height: 40px;
        margin-bottom: 5px;
      }

      .desktopSiteLink {
        padding: 20px 12px 32px 12px !important;
        height: 120px !important;
        a {
          font-size: 16px;
        }
        img {
          display: inline-block;
          margin-bottom: 18px;
          width: 56px;
          height: 48px;
        }
      }

    }
  }
}

.slider {
  padding-left: 65px;
  padding-right: 65px;
}